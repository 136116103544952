@tailwind base;
@tailwind components;
@tailwind utilities;

.sub-nav-wrapper {
  display: none;
  height: 0;
}

.sub-nav-radio {
  display: none;
}

.sidebar-icon path {
  stroke-width: 1.5;
}

.sub-nav-radio:checked ~ .sub-nav-wrapper {
  display: block;
  height: auto;
}

.caret-open {
  @apply hidden;
}

.sub-nav-radio:checked + .nav-label .caret-collapsed {
  @apply hidden;
}

.sub-nav-radio:checked + .nav-label .caret-open {
  @apply inline;
}

.nav-label {
  @apply w-full text-gray-300 hover:bg-gray-700 hover:text-white transition duration-500 ease-in-out flex items-center px-2 py-2 text-sm font-medium rounded-md cursor-pointer;
  @apply focus:outline-none focus:ring-2 focus:ring-indigo-500;
}

.nav-item {
  @apply text-gray-300 hover:bg-gray-700 hover:text-white flex items-center pl-11 pr-2 py-2 text-sm font-medium rounded-md;
}

.sub-nav-radio:checked ~ .nav-label {
  @apply bg-gray-900 text-white;
}

/* Generic Table */
.table {
  @apply min-w-full divide-y divide-gray-200;
}

.thead {
  @apply bg-gray-50;
}

.th {
  @apply px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider;
}

.odd-row {
  @apply bg-white;
}

.even-row {
  @apply bg-gray-50;
}

.table-row {
  @apply bg-white hover:bg-gray-50;
  @apply border-b border-gray-100;
}

.table-row:last-of-type {
  @apply border-b-0;
}

.td {
  @apply px-6 py-4 whitespace-nowrap text-sm text-gray-500;
}

/*blockquote {*/
/*  background: #f1f5f9;*/
/*  border-left: 4px solid #1e293b;*/
/*  margin-top: 8px;*/
/*  padding: 0.5em 10px;*/
/*  quotes: '\201C''\201D''\2018''\2019';*/
/*}*/
/*blockquote + blockquote {*/
/*  margin-top: 0;*/
/*  margin-bottom: 8px;*/
/*}*/
/*blockquote p {*/
/*  display: inline;*/
/*}*/

.slate-ul {
  list-style: disc;
  /*@apply list-disc list-inside*/
}

.slate-ol {
  list-style: decimal;
  /*@apply list-decimal list-inside*/
}

.toolbar {
  padding: 0 !important;
  margin: 0 !important;
  border-bottom: none !important;
  @apply mb-2 gap-1 flex flex-wrap;
}

.slate-ToolbarButton {
  @apply bg-gray-50 text-gray-500 hover:bg-gray-100 hover:text-gray-900;
  @apply cursor-pointer rounded-md bg-gray-50 text-gray-500 hover:bg-gray-100;
  @apply relative inline-flex items-center justify-center py-1 px-2 text-sm font-medium whitespace-nowrap;
  width: 32px !important;
  height: 32px !important;
}

.slate-ToolbarButton-active {
  @apply bg-gray-800 text-white hover:bg-gray-800 !important;
}

.slate-ToolbarButton > svg {
  width: 0.875rem !important;
  height: 0.875rem !important;
}

.slate-ToolbarButton .toolbar-sub-icon {
  width: 0.7rem !important;
  height: 0.7rem !important;
  bottom: -1px;
  position: relative;
}
